'use client';
import { Chart } from 'app/components/Chart';
import {
  type NetZeroChartProps,
  NetZeroChartType,
} from 'app/components/Chart/variants/NetZero';
import { Dropdown } from 'app/components/Dropdown';
import InPageError from 'app/components/Error/InPageError';
import Loader from 'app/components/Loader';
import useNetZeroTrajectoryData from 'app/hooks/useNetZeroTrajectoryData';
import { DATA_COLOR_SCALE_SCHEME } from 'app/lib/datavizColors';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import numbro from 'numbro';
import { type ReactNode, useState } from 'react';

type ChartTypeDropdownProps = Dropdown.DropdownDefaultProps<NetZeroChartType>;

type NetZeroOverviewProps = {
  className?: string;
  chartType?: NetZeroChartType;
  legendPosition?: 'top' | 'bottom';
  children?: ReactNode;
  withDropdown?: boolean;
  additionalDatasets?: NetZeroChartProps['datasets'];
} & (
  | {
      title: string;
      subTitle?: string;
      children?: never;
    }
  | {
      title?: never;
      subTitle?: never;
      children?: ReactNode;
    }
);

export default function NetZeroOverview({
  title,
  chartType,
  className,
  subTitle,
  children,
  legendPosition = 'top',
  withDropdown = true,
  additionalDatasets = [],
}: NetZeroOverviewProps) {
  const t = useTranslations();
  const CHART_TYPE_DROPDOWN_OPTIONS: ChartTypeDropdownProps['options'] = [
    {
      label: t('NetZeroOverview.totalFootprint'),
      value: NetZeroChartType.TotalFootprint,
      description: t('NetZeroOverview.totalFootprintDescription'),
    },
    {
      label: t('NetZeroOverview.footprintPerUnit'),
      value: NetZeroChartType.PerUnitFootprint,
      description: t('NetZeroOverview.footprintPerUnitDescription'),
    },
  ];

  const [chartTypeValue, setChartTypeValue] = useState<NetZeroChartType>(
    chartType || CHART_TYPE_DROPDOWN_OPTIONS[0].value,
  );
  return (
    <section className={classNames('w-full', className)}>
      <div className="flex flex-row items-center justify-between">
        <div>
          {children ? (
            children
          ) : (
            <>
              <h2 className="text-carbon-800 text-base font-medium">{title}</h2>
              <h3 className="text-carbon-500 text-xs font-medium mb-3">
                {subTitle}
              </h3>
            </>
          )}
        </div>

        {withDropdown && (
          <Dropdown.Default
            options={CHART_TYPE_DROPDOWN_OPTIONS}
            value={chartTypeValue}
            onChange={setChartTypeValue}
            label={t('NetZeroOverview.view')}
            align="right"
          />
        )}
      </div>

      <div className="rounded-xl border-[1.5px] border-carbon-200 bg-white p-4 flex flex-col">
        <NetZeroChartContainer
          additionalDatasets={additionalDatasets.filter(
            (aDataset) => aDataset.type === chartTypeValue,
          )}
          legendPosition={legendPosition}
          chartType={chartTypeValue}
        />
      </div>
    </section>
  );
}

function NetZeroChartContainer({
  chartType,
  legendPosition,
  additionalDatasets,
}: {
  chartType: NetZeroChartType;
  legendPosition: 'top' | 'bottom';
  additionalDatasets: NetZeroChartProps['datasets'];
}) {
  const {
    rawData,
    isLoading,
    formattedDataPoints,
    baselineYear,
    baselineYearValue,
    modelingBaselineYear,
    modelingBaselineYearValue,
  } = useNetZeroTrajectoryData(chartType);
  const t = useTranslations();
  if (isLoading) {
    return <Loader type="inline" className="my-12" />;
  }
  if (!rawData || rawData.length === 0) {
    return (
      <InPageError
        title={t('NetZeroOverview.noData.title')}
        description={t('NetZeroOverview.noData.description')}
        icon="circle-stack"
      />
    );
  }

  const axisYSettings = getSettingsByType(chartType);

  return (
    <Chart.NetZero
      plotLegendPosition={legendPosition}
      axisYSettings={axisYSettings}
      datasets={[
        ...additionalDatasets,
        {
          type: chartType,
          forecast: {
            startYear:
              baselineYear >= modelingBaselineYear
                ? baselineYear
                : modelingBaselineYear,
            startValue:
              baselineYear >= modelingBaselineYear
                ? baselineYearValue
                : modelingBaselineYearValue,
            yearlyVariation: -8 / 100,
            type: 'percentageForecast',
          },
          settings: {
            key: 'netZero',
            text: t('NetZeroOverview.netZeroPath'),
            color: DATA_COLOR_SCALE_SCHEME[1],
          },
        },
        {
          type: chartType,
          settings: {
            key: 'actual',
            text:
              /* These numbers can be found thanks to this:
              - 2% is the growth rate of the industry
              - 0.5% is almost stable. CF This thread https://carbonfact.slack.com/archives/C06E2GXVDNJ/p1709561246332459
            */
              chartType === NetZeroChartType.TotalFootprint
                ? t('NetZeroOverview.businessAsUsualTotalFootprint')
                : t('NetZeroOverview.businessAsUsualPerUnitFootprint'),
            color: DATA_COLOR_SCALE_SCHEME[3],
          },
          data: {
            points: formattedDataPoints,
          },
          forecast: {
            dashed: true,
            startYear:
              baselineYear >= modelingBaselineYear
                ? baselineYear
                : modelingBaselineYear,
            startValue:
              baselineYear >= modelingBaselineYear
                ? baselineYearValue
                : modelingBaselineYearValue,
            type: 'percentageForecast',
            yearlyVariation:
              chartType === NetZeroChartType.TotalFootprint
                ? 2 / 100
                : -0.5 / 100,
          },
        },
      ]}
    />
  );
}

export const getSettingsByType = (type: NetZeroChartType) => {
  switch (type) {
    case NetZeroChartType.TotalFootprint:
      return {
        label: 'k Tons CO2e',
        format: (number: number) =>
          numbro(number / 1000).format({
            average: true,
            mantissa: 1,
            optionalMantissa: true,
            output: 'number',
          }),
      };
    case NetZeroChartType.PerUnitFootprint:
      return {
        label: 'kg CO2e/unit',
      };
  }
};
